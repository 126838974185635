import { camelize } from "camelscore";
import { map, prop, compose, compact } from "lodash/fp";
import { Id } from "storefront/lib/Id";
import { ListingIndexType } from "storefront/Algolia/IndexType";
import createInsecureAlgoliaClient from "storefront/Algolia/Client/createInsecureClient";
import { getAlgoliaIndexName } from "storefront/Config/PublicConfig/findAlgoliaIndexName";
import getConfig from "storefront/GrailedAPI/v1/Config/getConfig";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";

/**
 * @name fetchByIds
 * @description Returns an array of Listings using the Listing Index
 *
 * @param {Array<string>} ids - an array of ids, note that they are STRINGS.
 * @return {Promise<Array<AlgoliaListing>>}
 */
const fetchByIds = (
  ids: Array<Id>,
  indexKey: ListingIndexType | null | undefined,
): Promise<Array<AlgoliaListing>> => {
  const objectIdStrings = map(String, ids);

  return Promise.all([createInsecureAlgoliaClient(), getConfig()]).then(
    ([client, config]) => {
      const indexName = getAlgoliaIndexName(
        "listings",
        indexKey || "default",
      )(config);

      const index = client.initIndex(indexName);

      return index
        .getObjects(objectIdStrings)
        .then(compose([camelize, compact, prop("results")]));
    },
  );
};

export default fetchByIds;
