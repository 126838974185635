import React from "react";
import cn from "classnames";
import Subhead from "storefront/components/Subhead";
import Footnote from "storefront/components/Footnote";
import Image from "storefront/components/Image";
import type { Capsule } from "../../../Capsule";
import styles from "./CapsuleCard.module.scss";

type Props = {
  capsule: Capsule;
  className?: string;
  handleClick?: (_event: React.MouseEvent<HTMLAnchorElement>) => void;
};

const CapsuleCard = ({ className, capsule, handleClick }: Props) => {
  const {
    name,
    detailPage: { slug, description, heroUrl },
  } = capsule;

  return (
    <a
      href={`/collections/${slug}`}
      className={`${styles.capsuleCard} ${className}`}
      onClick={handleClick}
    >
      <div
        className={cn(styles.image, {
          [`${className}-Image`]: className,
        })}
      >
        <Image src={heroUrl || ""} alt="" width={378} height={230} />
      </div>
      <div
        className={cn(styles.info, {
          [`${className}-Info`]: className,
        })}
      >
        <Subhead
          className={cn(styles.title, {
            [`${className}-Title`]: className,
          })}
        >
          {name}
        </Subhead>
        <Footnote
          className={cn(styles.description, {
            [`${className}-Description`]: className,
          })}
        >
          {description}
        </Footnote>
      </div>
    </a>
  );
};

export default CapsuleCard;
