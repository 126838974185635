import type { Id } from "../../../../../lib/Id";
import { get } from "../../../../request";

type Data = {
  listingIds: Array<Id>;
};
type Response = {
  data: Data;
};

const unpack = (res: Response): Set<Id> => new Set(res.data.listingIds);

const getListingViews = (userId: Id): Promise<Set<Id>> => {
  const path = `/api/users/${userId}/recent_views/listings/`;
  return get<Response>(path).then(unpack);
};

export default getListingViews;
