/**
 * @namespace Listing
 */
import { Hit } from "react-instantsearch-core";
import { compose, isEmpty, prop, negate, get, getOr } from "lodash/fp";
import { emptyTimestamp } from "storefront/lib/Date";
import { Id, emptyId } from "storefront/lib/Id";
import { Department } from "storefront/Department";
import {
  GrailedAPIDesigner,
  empty as emptyDesigner,
} from "storefront/Designer";
import { FeeBreakdown } from "storefront/FeeBreakdown";
import { SalesTax } from "storefront/Listing/SalesTax";
import { ShippingLabelField as ListingShippingLabel } from "storefront/Listing/ShippingLabelField";
import { ListingAuthenticationRequest } from "storefront/ListingAuthenticationRequest";
import { Measurement } from "storefront/Measurement";
import { GrailedAPIPhoto, empty as emptyPhoto } from "storefront/Photo";
import { PostalAddress } from "storefront/PostalAddress";
import { PowerSellerGroup } from "storefront/PowerSellerGroup";
import { SellerPayoutDisplay } from "storefront/SellerPayoutDisplay";
import { SellerRating } from "storefront/SellerRating";
import { Shipment } from "storefront/Shipment";
import {
  Values as ShippingValues,
  empty as emptyShipping,
} from "storefront/Shipping/Values";
import { Trait } from "storefront/Trait";
import { TransactionPaymentStatus, PENDING } from "storefront/Transactions";
import { User, empty as emptyUser } from "storefront/User";
import { InternalListing } from "storefront/Listing/Internal";
import { Gateway } from "storefront/Transaction";
import { LightConversationListing } from "storefront/Conversation/LightConversation";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import {
  ExpeditedShippingInfo,
  GrailedAPIHeavyListing,
} from "./GrailedAPIHeavyListing";
import {
  AlgoliaMyItemsListing,
  isMyItemsListing,
} from "./AlgoliaMyItemsListing";
import { GrailedAPILightListing } from "./GrailedAPILightListing";

// NOTE: Type for deterministic status of a listing. This does NOT include visibility status (hidden).
//   The precedence chain is: deleted/sold -> active
export type ListingStatus =
  | "active"
  | "sold" // sold is true
  | "deleted"; // deleted is true

export type ListingReviewState =
  | "connect_paypal" // seller has no paypal merchant
  | "rejected" // changes have been requested on the listing
  | "under_review" // currently in the moderator queue
  | "active" // a purchasable listing
  | "not_in_review"; // listing is not purchasable and not in the moderator queue

export type Condition =
  | "is_not_specified"
  | "is_new"
  | "is_gently_used"
  | "is_used"
  | "is_worn";

export type IssueRefundInfo = {
  readonly transactionId: string | null;
  readonly url: string;
};

export type AutomaticAction = "refund" | "disburse";

export type DisbursementMode = "delivery" | "carrier_scan";

export type WithholdingInfo = {
  automaticAction: AutomaticAction;
  refundableAt: string | null | undefined;
  extendedAt: string | null | undefined;
  actionableAt: string | null | undefined;
  disbursementMode: DisbursementMode;
};

export const emptyWithholdingInfo: WithholdingInfo = {
  automaticAction: "refund",
  refundableAt: null,
  extendedAt: null,
  actionableAt: null,
  disbursementMode: "delivery",
};

export const PURCHASED_VIA_STRIPE = "stripe";
export const PURCHASED_VIA_PAYPAL = "paypal_marketplaces";
export type PurchasedVia =
  | typeof PURCHASED_VIA_STRIPE
  | typeof PURCHASED_VIA_PAYPAL;

/**
 * @deprecated
 * @description DEPRECATED - Eventually, the Listing type will be a union type of all our listing types!
 * Until then, please use a more specific type when you know what listing type you're dealing with instead of this one.
 * Try to use GrailedAPIHeavyListing, GrailedAPILightListing, or AlgoliaListing!
 * If you add more properties to those from our backend, also make sure to update the appropriate types!
 */
export type Listing = {
  id: Id;
  activeRejection?: ListingAuthenticationRequest;
  badges: Array<string>;
  // TODO: this may not be serialized anymore
  bumpedAt: string | null | undefined;
  buyerId?: Id;
  buyNow: boolean;
  category: string;
  categoryPath: string;
  condition: Condition;
  coverPhoto: GrailedAPIPhoto;
  createdAt: string;
  currency: string;
  deleted: boolean;
  deletedViaRefund: boolean;
  description: string;
  /**
   * @deprecated
   */
  designer: GrailedAPIDesigner;
  designerNames: string;
  designers: Array<GrailedAPIDesigner>;
  dropped: boolean;
  expeditedShippingInfo?: ExpeditedShippingInfo;
  externalSellerReference?: string | null;
  feeBreakdown?: FeeBreakdown;
  followed: boolean;
  followerCount: number;
  hashtags: Array<string>;
  hidden: boolean;
  hiddenFromAlgolia: boolean;
  issueRefundInfo?: IssueRefundInfo;
  lastRelistPath?: string;
  makeOffer: boolean;
  // this is incorrectly typed -- please stop using `Listing` so we can fix all these properties
  // measurements is only on light listing. please see my IMPORTANT comment above. ty!
  measurements: Array<Measurement>;
  minimumPrice?: number; // should be: minimumPrice?: number | null;
  needsTracking?: boolean;
  paymentDestination?: Gateway;
  paymentStatus: TransactionPaymentStatus; // does NOT exist on LightListing, should be optional. also is NULL when not sold.
  photos: Array<GrailedAPIPhoto>;
  prettyPath: string;
  prettySize: string;
  price: number;
  priceDrops: Array<number>;
  priceUpdatedAt: string;
  productId: Id | null | undefined;
  published?: boolean;
  purchasedVia?: PurchasedVia;
  repostId?: Id;
  reposts?: number;
  returnAddress?: PostalAddress;
  returnAddressCountryCode: string | null;
  reviewState?: ListingReviewState;
  salesTax?: SalesTax;
  seller: User; // Should be optional because not on Light listing
  sellerId?: number;
  sellerPayoutDisplay?: SellerPayoutDisplay;
  sellerRating: SellerRating | null | undefined;
  shipments?: Array<Shipment>;
  // shipping should be optional bc it doesn't exist on light listings, TODO make optional
  shipping: ShippingValues;
  readonly shippingLabel: ListingShippingLabel | null;
  size: string;
  exactSize?: string | null;
  skuId?: number;
  sold: boolean;
  soldAt: string | null | undefined;
  soldPrice: number;
  soldPriceIncludesShipping: boolean | null;
  soldShippingPrice: number | null;
  soldWithLabel?: boolean | null;
  status?: ListingStatus;
  strata: string;
  subcategory: string;
  title: string;
  traits?: Array<Trait>;
  trueCreatedAt: string;
  url: string;
  user: User;
  userId?: number;
  wasDigitallyAuthenticated?: boolean;
  withheldAt?: string;
  withholdingInfo?: WithholdingInfo;
  department: Department;
  inSavedList: boolean;
};

export const empty: Listing = {
  id: emptyId,
  badges: [],
  bumpedAt: null,
  buyNow: false,
  category: "",
  categoryPath: "",
  condition: "is_not_specified",
  coverPhoto: emptyPhoto,
  createdAt: emptyTimestamp,
  currency: "",
  deleted: false,
  deletedViaRefund: false,
  description: "",
  designer: emptyDesigner,
  designerNames: "",
  designers: [emptyDesigner],
  dropped: false,
  followed: false,
  followerCount: 0,
  hashtags: [],
  measurements: [],
  hidden: false,
  hiddenFromAlgolia: false,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'IssueRefund... Remove this comment to see the full error message
  issueRefundInfo: null,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
  lastRelistPath: null,
  makeOffer: false,
  paymentStatus: PENDING,
  photos: [],
  prettyPath: "",
  prettySize: "",
  price: 0,
  priceDrops: [],
  priceUpdatedAt: emptyTimestamp,
  productId: null,
  returnAddressCountryCode: null,
  seller: emptyUser,
  sellerRating: null,
  shipping: emptyShipping,
  shippingLabel: null,
  size: "",
  sold: false,
  soldAt: null,
  soldPrice: 0,
  soldPriceIncludesShipping: null,
  soldShippingPrice: null,
  strata: "",
  subcategory: "",
  title: "",
  trueCreatedAt: emptyTimestamp,
  url: "",
  user: emptyUser,
  department: "menswear",
  inSavedList: false,
};

export type LegacyListing = Listing & {
  _tag: "Legacy";
};

export type LegitCheckRequest = {
  reason: string | null | undefined;
  notes: string | null | undefined;
};

export type ListingReport = {
  comment: string;
};

type QueueType = "high_risk" | "low_risk" | "legit_check" | null;

export type UnreviewedListing = Listing & {
  queueType: QueueType;
  queuedAt: number;
  mostRecentLegitCheckRequest: LegitCheckRequest | null | undefined;
  mostRecentListingReport: ListingReport | null | undefined;
  mostRecentRejection: ListingAuthenticationRequest | null | undefined;
  published: boolean;
  powerSellerGroup: PowerSellerGroup | null | undefined;
};

export type ListingRiskFactors = {
  newUser: boolean;
  published: boolean;
  totalFakesListed: number;
  recentFakesListed: number;
  mostRecentLegitCheckRequest: LegitCheckRequest | null | undefined;
  mostRecentListingReport: ListingReport | null | undefined;
  mostRecentRejection: ListingAuthenticationRequest | null | undefined;
};

export const PHOTO_PLACEHOLDER_URL =
  "https://ds6dnbdlsnuyn.cloudfront.net/assets/placeholder.png";

/*
  Ok, so - we get two types of "listings" from the backend - one that has been serialized, and
  the other from `.to_json` - the former has "photos", the latter has "coverPhoto". Hence this.
  */
type ObjectWithPhotos =
  | {
      photos: Array<GrailedAPIPhoto>;
    }
  | {
      coverPhoto: GrailedAPIPhoto;
    };

export const hasCoverPhoto = (listing: ObjectWithPhotos): boolean =>
  compose([negate(isEmpty), prop("coverPhoto")])(listing);

const getPhotoObject = (listing: ObjectWithPhotos): GrailedAPIPhoto | null => {
  if ("photos" in listing && get("photos[0]", listing))
    return listing.photos[0];

  if ("coverPhoto" in listing) return listing.coverPhoto;

  return null;
};

export const getListingPhotoUrl = (listing: ObjectWithPhotos): string =>
  getOr(PHOTO_PLACEHOLDER_URL, "url", getPhotoObject(listing));

export const getListingPhotoRotate = (listing: ObjectWithPhotos): number =>
  getOr(0, "rotate", getPhotoObject(listing));

/**
 * Sometimes, a Listing will have an entire seller (User); other times, the Listing will only have
 * a sellerId (Id).
 */
export const getSellerId = (
  listing:
    | Listing
    | GrailedAPIHeavyListing
    | GrailedAPILightListing
    | InternalListing
    | LightConversationListing
    | AlgoliaListing,
): Id | null => {
  if ("sellerId" in listing && listing.sellerId) return listing.sellerId;

  if ("seller" in listing && listing.seller) return listing.seller.id;

  // NOTE: never returns null for these listing types.
  // Can remove this once we stop passing in the generic `Listing`.
  return null;
};

export const seller = (listing: Listing): User => listing.seller;

export const isSeller =
  (userId: Id) =>
  (
    listing:
      | Listing
      | AlgoliaMyItemsListing
      | GrailedAPILightListing
      | GrailedAPIHeavyListing
      | LightConversationListing,
  ): boolean => {
    // AlgoliaMyItemsListing types are only visible to their seller
    if (isMyItemsListing(listing)) return true;

    return userId === getSellerId(listing);
  };

export const isBuyer =
  (userId: Id) =>
  (
    listing: Listing | GrailedAPIHeavyListing | LightConversationListing,
  ): boolean => {
    const { buyerId } = listing;
    return userId === buyerId;
  };

const LOW_BALL_RATIO = 0.6;

export const getLowballAmount = ({
  price,
}: Listing | GrailedAPIHeavyListing): number =>
  Math.ceil(price * LOW_BALL_RATIO);

// Adds additional fields we rely on that are confirmed to come from Algolia
// but are not included in the provided type for *reasons*
export type AlgoliaListingHit = Hit<Listing> & {
  __queryID?: string | null;
  __position?: number | null;
};
