import React from "react";
import classnames from "classnames";

type Props = {
  className: string;
  item: React.ReactElement<React.ComponentProps<any>, any>;
};

/**
 * @name Carousel.CarouselItem
 * @description A wrapper that wraps each Carousel Item, giving it a special className and style.
 * @param {Props} props
 * @returns {React$Element<*>}
 */
const CarouselItem = ({ className, item }: Props) => (
  <div className={classnames("-carousel-item", className)}>{item}</div>
);

export default CarouselItem;
