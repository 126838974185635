import { Id, emptyId } from "storefront/lib/Id";
import { ListingAuthenticationRequest } from "storefront/ListingAuthenticationRequest";

// Photo Serializer
export type GrailedAPIPhoto = {
  id: Id;
  url: string;
  rotate: number;
  width: number | null | undefined;
  height: number | null | undefined;
  createdAt: string;
};

// Entire Photo in database and Algolia
export type Photo = GrailedAPIPhoto & {
  listingId: Id | null | undefined;
  position: number;
  updatedAt: string;
};

export const empty: GrailedAPIPhoto = {
  id: emptyId,
  url: "",
  rotate: 0,
  width: 0,
  height: 0,
  createdAt: "",
};

export const emptyPhoto: Photo = {
  ...empty,
  listingId: null,
  position: 0,
  updatedAt: "",
};

export type PhotoConversionOptions = {
  width: number;
  height: number;
  fit?: "crop" | "clip" | "scale";
  rotate?: number;
  align?: string;
  quality?: number;
};

export const photoCreatedAfterRejection = (
  photo: GrailedAPIPhoto,
  rejection: ListingAuthenticationRequest | null | undefined,
) => !!rejection && new Date(photo.createdAt) > new Date(rejection.createdAt);
