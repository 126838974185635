import React from "react";
import cn from "classnames";
import styles from "./Wrapper.module.scss";

type Props = {
  children: React.ReactNode;
  className?: string;
  "data-cy"?: string;
};

const Wrapper = ({ children, className, "data-cy": dataCy }: Props) => (
  <div className={cn(styles.wrapper, className)} data-cy={dataCy}>
    {children}
  </div>
);

export default Wrapper;
