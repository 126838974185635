import { camelize } from "camelscore";
import type { Hit, BaseHit } from "instantsearch.js";
import { Id } from "storefront/lib/Id";
import { Department } from "storefront/Department";
import { ListingAuthenticationRequest } from "storefront/ListingAuthenticationRequest";
import { Photo } from "storefront/Photo";
import { LightConversationListing } from "storefront/Conversation/LightConversation";
import { GrailedAPIHeavyListing } from "./GrailedAPIHeavyListing";
import { GrailedAPILightListing } from "./GrailedAPILightListing";
import { AlgoliaListing, AlgoliaListingDesigner } from "./AlgoliaListing";
import { Listing, ListingReviewState } from "./index";

export type AlgoliaMyItemsListing = {
  activeRejection: ListingAuthenticationRequest | null;
  badges: Array<string>;
  bumpedAt: string;
  categoryPath: string;
  coverPhoto: Photo;
  createdAt: string;
  department: Department;
  designerNames: string;
  designers: Array<AlgoliaListingDesigner>;
  externalSellerReference: string | null;
  followerno: number;
  hashtags: Array<string>;
  hidden: boolean;
  id: Id;
  prettyPath: string;
  price: number;
  priceDrops: Array<number>;
  priceUpdatedAt: string | null; // TODO: priceUpdatedAt won't be nil after backfill
  priceUpdatedAtI: number | null; // TODO: priceUpdatedAtI won't be nil after backfill
  reviewState: ListingReviewState;
  size: string;
  title: string;
  _tag: "AlgoliaMyItems";
};

export const myItemsHitToListing = (
  hit: Hit<BaseHit>,
): AlgoliaMyItemsListing => ({
  ...camelize(hit),
  _tag: "AlgoliaMyItems",
});

export const isMyItemsListing = (
  listing:
    | Listing
    | GrailedAPILightListing
    | AlgoliaListing
    | AlgoliaMyItemsListing
    | GrailedAPIHeavyListing
    | LightConversationListing,
): listing is AlgoliaMyItemsListing =>
  // eslint-disable-next-line no-underscore-dangle
  "_tag" in listing && listing._tag === "AlgoliaMyItems";
