import React from "react";
import useCurrentDevice from "storefront/hooks/useCurrentDevice";
import imageSrc from "storefront/components/Image/imageSrc";
import type { IndexedList } from "storefront/lib/IndexedList";
import type { SrcParams } from "storefront/components/Image/imageSrc";

type SrcProps = { url: string; params?: SrcParams };

type Props = {
  TagName: "div" | "a" | "span" | "h3";
  sources: IndexedList<SrcProps>;
  fadeIn?: boolean;
  style?: IndexedList<any>;
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLElement>;

const BackgroundImage = ({
  TagName,
  sources,
  fadeIn,
  style,
  children,
  ...rest
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const device = useCurrentDevice();
  const bestSource =
    sources[device] || sources.desktop || Object.values(sources)[0];
  const src = imageSrc(bestSource.url, bestSource.params);

  const completeStyle: IndexedList<any> = { ...style };

  if (src) {
    completeStyle.backgroundImage = `url(${src})`;
  }

  if (src && fadeIn) {
    completeStyle.transition = "opacity 0.25s linear";
  }

  if (!src && fadeIn) {
    completeStyle.opacity = 0;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TagName data-testid="BackgroundImage" style={completeStyle} {...rest}>
      {children}
    </TagName>
  );
};

export default BackgroundImage;
