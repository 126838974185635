import { isNumber } from "lodash/fp";

export type ModuleType =
  | "Listings From Feed"
  | "Feed Grid"
  | "Feed Carousel"
  | "Full Width CTA"
  | "Sku Grid"
  | "Featured Capsules"
  | "Category Grid"
  | "Featured Block"
  | "Visual Filters"
  | "Recently Viewed"
  | "Popular Searches"
  | "Designer Grid"
  | "Popular Collaborations"
  | "Listings From Designer Category"
  | "Related Articles"
  | "Related Collections"
  | "Recommendation Module"
  | "Saved Searches"
  | "Designer Cards"
  | "Listing Carousel"
  | "DCO Entry Point"
  | "Seller Feedback"
  | "Big Video Module"
  | "Similar Listings Narrow"
  | "Similar Listings Wide"
  | "Related Pages";

export type ItemType =
  | "listing card"
  | "feed item"
  | "CTA"
  | "sku card"
  | "capsule card"
  | "category"
  | "popular search"
  | "designer"
  | "article card"
  | "popular collaboration"
  | "designer card"
  | "popular search query"
  | "designer category"
  | "designer department"
  | "designer collaboration"
  | "designer keyword"
  | "keyword";

export type ModuleProperties = {
  moduleType: ModuleType;
  moduleName: string;
  moduleNameContentful?: string;
  modulePosition: number;
  itemType: ItemType;
  itemPosition?: number | string;
  itemId?: number | string;
  itemName: string;
  itemNameContentful?: string;
  imageUrl?: string;
  from: string;
  pageType?: string;
  listingId?: string | number;
  sellerId?: string | number;
};

export type ModuleClickedEvent = {
  object: "module";
  action: "clicked";
  properties: {
    module_type: ModuleType;
    module_name: string;
    module_name_contentful?: string;
    module_position: number;
    item_type: ItemType;
    item_position?: number | string;
    item_id?: number | string;
    item_name: string;
    item_name_contentful?: string;
    image_url?: string;
    from: string;
    page_type?: string;
    listing_id?: string | number;
    seller_id?: string | number;
  };
};

const moduleClicked = (properties: ModuleProperties): ModuleClickedEvent => ({
  object: "module",
  action: "clicked",
  properties: {
    module_type: properties.moduleType,
    module_name: properties.moduleName,
    module_name_contentful: properties.moduleNameContentful,
    module_position: properties.modulePosition + 1,
    item_type: properties.itemType,
    item_position: isNumber(properties.itemPosition)
      ? properties.itemPosition + 1
      : properties.itemPosition,
    item_id: properties.itemId,
    item_name: properties.itemName,
    item_name_contentful: properties.itemNameContentful,
    image_url: properties.imageUrl,
    from: properties.from,
    page_type: properties.pageType,
    listing_id: properties.listingId,
    seller_id: properties.sellerId,
  },
});

export default moduleClicked;
