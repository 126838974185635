/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from "classnames";
import React from "react";
import { TEXT_CLASS_NAME } from "storefront/components/Text";
import styles from "./Heading.module.scss";

type Props = {
  title: string;
  trackClick?: (arg0: React.MouseEvent<any>) => void;
  ctaLink?: string;
  ctaText?: string;
  className?: string;
  rel?: string;
};
const titleClassName = `${styles.title} ${TEXT_CLASS_NAME}`;

const Heading = ({
  title,
  trackClick,
  ctaLink,
  ctaText,
  className,
  rel,
}: Props) => (
  <div className={classNames(styles.heading, className)}>
    {ctaLink ? (
      <>
        <h2 data-cy="module-heading-title" className={`${titleClassName}`}>
          <a
            onClick={trackClick}
            className={styles.headingCtaLink}
            href={ctaLink}
            rel={rel}
          >
            {title}
          </a>
        </h2>
        {ctaText ? (
          <a
            onClick={trackClick}
            data-cy="module-heading-cta"
            className={styles.cta}
            href={ctaLink}
            rel={rel}
          >
            {ctaText}
          </a>
        ) : null}
      </>
    ) : (
      <h2 data-cy="module-heading-title" className={`${titleClassName}`}>
        {title}
      </h2>
    )}
  </div>
);
export default Heading;
