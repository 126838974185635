import React, { useState, useEffect } from "react";
import { take } from "lodash/fp";
import { useSelector } from "react-redux";
import { GlobalState } from "storefront/GlobalState";
import { PageType } from "storefront/Analytics/Event";
import Heading from "storefront/components/ListingPage/Modules/Heading";
import { Id } from "storefront/lib/Id";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import { TrackingItemProperties } from "storefront/Contentful/types";
import { TypedEntry } from "storefront/Contentful/TypedEntry";
import useAnalytics from "storefront/hooks/useAnalytics";
import moduleClicked from "storefront/Analytics/EventCreators/moduleClicked";
import fetchByIds from "storefront/Algolia/Listings/fetchByIds";
import getRecentlyViewed from "storefront/RecentlyViewed/get";
import ListingCarousel from "storefront/components/ListingCarousel";
import ListingCarouselWrapper from "storefront/components/ListingCarousel/Wrapper";

// TODO- Because this component doesn't utilize any Contentful fields, we could consider
// incorporating it into Embedded Feature instead of using up a dedicated content type.

export const RECENTLY_VIEWED = "moduleRecentlyViewed" as const;

export type RecentlyViewedModule = TypedEntry<
  typeof RECENTLY_VIEWED,
  Record<string, never>
>;

const MIN_LISTINGS = 6;

type Props = {
  from: string;
  position: number;
  currentListingId?: Id;
  pageType: PageType;
  pageTypeIdentifier?: Id;
  pageTypeName?: string;
};

const maybeRemove =
  (listingId: Id | null | undefined) =>
  (recentlyViewed: Set<Id>): Set<Id> => {
    if (listingId) recentlyViewed.delete(listingId);
    return recentlyViewed;
  };

const fetchListingsIfEnough = (
  ids: Set<Id>,
): Promise<Array<AlgoliaListing>> => {
  if (ids.size >= 6) {
    return fetchByIds(Array.from(ids), "default");
  }

  return Promise.resolve([]);
};

const currentUserIdSelector = (state: GlobalState) =>
  state.session.currentUser.id;

/**
 * @name Modules.RecentlyViewed
 * @description Renders a Listing Carousel containing the current
 * user's recently viewed listings.
 * The user's Recently Viewed Listings are retrieved from localStorage.
 */
const RecentlyViewed = ({
  currentListingId,
  position,
  from,
  pageType,
  pageTypeIdentifier,
  pageTypeName,
}: Props) => {
  const [listings, setListings] = useState<Array<AlgoliaListing>>([]);

  const { track } = useAnalytics();

  const currentUserId: Id | null | undefined = useSelector(
    currentUserIdSelector,
  );

  useEffect(() => {
    getRecentlyViewed(currentUserId)
      .then(maybeRemove(currentListingId))
      .then(fetchListingsIfEnough)
      .then(setListings);
  }, [currentListingId, currentUserId]);

  const moduleName = "Recently Viewed";

  const trackClick = (properties: TrackingItemProperties) => () => {
    track(
      moduleClicked({
        ...properties,
        from,
        moduleType: moduleName,
        moduleName,
        moduleNameContentful: "Module: Recently Viewed",
        modulePosition: position,
      }),
    );
  };

  if (listings.length < MIN_LISTINGS) return null;

  return (
    <ListingCarouselWrapper className="Module--RecentlyViewed">
      <Heading title="Recently Viewed" />
      <ListingCarousel
        listings={take(12, listings)}
        trackClick={trackClick}
        numOfItemsToShow={6}
        moduleName={moduleName}
        moduleType={moduleName}
        pageType={pageType}
        pageTypeIdentifier={pageTypeIdentifier}
        pageTypeName={pageTypeName}
        from="recently_viewed"
      />
    </ListingCarouselWrapper>
  );
};

export default RecentlyViewed;
