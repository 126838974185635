import React from "react";
import type { Capsule } from "storefront/Capsule";
import type { TrackingItemProperties } from "storefront/Contentful/types";
import Carousel from "storefront/components/Carousel";
import CapsuleCard from "storefront/components/Modules/FeaturedCapsules/CapsuleCard";

// used classes are applying to CapsuleCard
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./CapsulesCarousel.module.scss";

type Props = {
  capsules: Array<Capsule>;
  listingPageCarousel: boolean;
  trackClick: (
    properties: TrackingItemProperties,
  ) => (_event: React.MouseEvent<HTMLAnchorElement>) => void;
};

/**
 * @name CapsulesCarousel
 * @description Display component for a carousel of capsules.
 */
const CapsulesCarousel = ({
  capsules,
  listingPageCarousel,
  trackClick,
}: Props) => {
  const capsuleProperties = (
    capsule: Capsule,
    index: number,
  ): TrackingItemProperties => ({
    itemName: capsule.name,
    itemNameContentful: capsule.name,
    itemPosition: index,
    itemType: "capsule card",
    imageUrl: capsule.detailPage.heroUrl ?? "",
  });

  const renderCapsuleItems = () =>
    capsules.map((capsule, index) => (
      <CapsuleCard
        key={capsule.id}
        capsule={capsule}
        className={styles.carouselCard}
        handleClick={trackClick(capsuleProperties(capsule, index))}
      />
    ));

  return (
    <div className={styles.capsulesCarousel}>
      <Carousel
        baseClassName={styles.carouselItem}
        itemsToShow={3}
        carouselItems={renderCapsuleItems()}
        listingPageCarousel={listingPageCarousel}
      />
    </div>
  );
};

export default CapsulesCarousel;
