import { Id } from "storefront/lib/Id";
import getListingViews from "storefront/GrailedAPI/v1/Users/RecentViews/Listings/getListingViews";
import getFromLocalStorage from "./getFromLocalStorage";

const get = (
  userId: Id | null | undefined,
  win: typeof window = window,
): Promise<Set<Id>> => {
  if (userId && userId !== -1) return getListingViews(userId);
  return Promise.resolve(getFromLocalStorage(win));
};

export default get;
