import React, { useState, useEffect, useRef, RefObject } from "react";
import classnames from "classnames";
import { toNumber, memoize } from "lodash/fp";
import { Event } from "storefront/Analytics/Event";
import Caret from "../Icons/Navigation/Caret";
import CarouselItem from "./CarouselItem";

import styles from "./Carousel.module.scss";

type Props = {
  itemsToShow: number;
  carouselItems: Array<React.ReactElement>;
  baseClassName: string;
  autoRotate?: boolean;
  rotateInterval?: number;
  trackClick?: (fromIndex: number, leftIndexInView: number) => Event;
  listingPageCarousel?: boolean;
  containerClassName?: string;
  wrapperClassName?: string;
};

type CarouselIndices = {
  leftIndexInView: number;
  rightIndexInView: number;
  fromIndex: number;
};

/**
 * @name Carousel
 * @description A Carousel wrapper that handles the tricky logic needed to move the carousel.
 * @param {Props} props
 * @returns {React$Element<*>}
 */

const Carousel = ({
  itemsToShow,
  carouselItems,
  baseClassName,
  autoRotate,
  rotateInterval = 6000,
  trackClick,
  listingPageCarousel,
  containerClassName,
  wrapperClassName,
}: Props) => {
  const carouselRef: RefObject<HTMLDivElement> = useRef(null);

  const initialCarouselIndices: CarouselIndices = {
    leftIndexInView: 0,
    rightIndexInView: itemsToShow,
    fromIndex: 0,
  };
  const [carouselIndices, setCarouselIndices] = useState<CarouselIndices>(
    initialCarouselIndices,
  );

  const shouldShowArrows = carouselItems.length > itemsToShow;
  const shouldAutoRotate = shouldShowArrows && autoRotate === true;

  let scrollTimer: ReturnType<typeof setInterval> | null;
  let autoRotateInterval: ReturnType<typeof setInterval>;

  const carouselItemClassName = classnames(baseClassName, styles.item);

  // NOTE(shirley): calculate the width of one curated list item
  // when we click the arrow, we want to slide (the number of items) * this value
  const calculateCarouselItemWidth = () => {
    const el = window.document.getElementsByClassName(carouselItemClassName)[0];
    const width = el.clientWidth;
    const style = window.getComputedStyle(el);
    return width + toNumber(style.marginRight.replace("px", ""));
  };
  const memoizedCarouselItemWidth = memoize(calculateCarouselItemWidth);

  const itemBuffer = (direction: "left" | "right"): number => {
    if (carouselRef.current) {
      const width = memoizedCarouselItemWidth();
      const buffer = direction === "right" ? 0.4 : 0.6;
      // If they've already viewed at least 40% of an item
      // then act as if they have viewed that item and
      // scroll past it in the next click,
      // otherwise treat it as unviewed and
      // include it in the displayed items after the click
      let itemPixelsRemaining = carouselRef.current.scrollLeft % width;

      if (itemPixelsRemaining > width * buffer) {
        itemPixelsRemaining = -(width - itemPixelsRemaining);
      }

      return -itemPixelsRemaining;
    }
    return 0;
  };

  const trackClickAfterShift = () => {
    if (trackClick) {
      trackClick(carouselIndices.fromIndex, carouselIndices.leftIndexInView);
    }
  };

  const trackAfterSetState = (fromClick: boolean): void => {
    if (fromClick) {
      trackClickAfterShift();
    }
  };

  const stopAutoRotate = () => {
    clearInterval(autoRotateInterval);
  };

  const shiftViewRight = (fromClick: boolean): void => {
    const prevRightIndexInView = carouselIndices.rightIndexInView;
    const prevLeftIndexInView = carouselIndices.leftIndexInView;

    const newCarouselIndices: CarouselIndices = {
      leftIndexInView: prevRightIndexInView,
      rightIndexInView: prevRightIndexInView + itemsToShow,
      fromIndex: prevLeftIndexInView,
    };
    setCarouselIndices(newCarouselIndices);

    trackAfterSetState(fromClick);

    if (
      shouldAutoRotate &&
      carouselIndices.rightIndexInView === carouselItems.length
    ) {
      stopAutoRotate();
    }
  };

  const scrollCarousel = (distance: number, speed: number): void => {
    if (scrollTimer) {
      return;
    }

    let scrollAmount = 0;
    // scrolling right
    let step = 50;
    let done = () => scrollAmount + step >= distance;

    if (distance < 0) {
      // scrolling left
      step = -step;
      done = () => distance >= scrollAmount + step;
    }

    scrollTimer = setInterval(() => {
      if (carouselRef.current) {
        if (done() && scrollTimer) {
          window.clearInterval(scrollTimer);
          carouselRef.current.scrollLeft += distance - scrollAmount;
          scrollTimer = null;
        } else {
          carouselRef.current.scrollLeft += step;
          scrollAmount += step;
        }
      }
    }, speed);
  };

  const shiftToBeginning = (fromClick: boolean): void => {
    const newCarouselIndices: CarouselIndices = {
      leftIndexInView: 0,
      rightIndexInView: itemsToShow,
      fromIndex: carouselItems.length - 1,
    };
    setCarouselIndices(newCarouselIndices);

    trackAfterSetState(fromClick);
  };

  const shiftRight = (fromClick: boolean): void => {
    if (carouselRef.current) {
      const width = memoizedCarouselItemWidth();
      const cutoff = carouselItems.length * width - itemsToShow * width;

      if (carouselRef.current.scrollLeft <= cutoff - 25) {
        const rightItemBuffer = itemBuffer("right");
        shiftViewRight(fromClick);
        scrollCarousel(rightItemBuffer + width * itemsToShow, 16);
      }

      shiftToBeginning(fromClick);
      scrollCarousel(-carouselRef.current.scrollLeft, 10);
    }
  };

  const startAutoRotate = () => {
    autoRotateInterval = setInterval(() => shiftRight(false), rotateInterval);
  };

  // Toggle auto-rotation of the carousel depending on if the window is visible or hidden
  const toggleAutoRotate = () => {
    if (document.hidden) {
      stopAutoRotate();
    } else if (shouldAutoRotate) {
      startAutoRotate();
    }
  };

  useEffect(() => {
    toggleAutoRotate();
    // Detect if window is visible or hidden (i.e. not an active tab or minimized window)
    // We don't want to rotate if the page is hidden
    document.addEventListener("visibilitychange", toggleAutoRotate);

    return function cleanup() {
      if (shouldAutoRotate) {
        stopAutoRotate();
      }
    };
  });

  const shiftViewLeft = (fromClick: boolean): void => {
    const prevLeftIndexInView = carouselIndices.leftIndexInView;

    const newCarouselIndices: CarouselIndices = {
      leftIndexInView: prevLeftIndexInView - itemsToShow,
      rightIndexInView: prevLeftIndexInView,
      fromIndex: prevLeftIndexInView,
    };
    setCarouselIndices(newCarouselIndices);

    trackAfterSetState(fromClick);
  };

  const shiftToEnd = (fromClick: boolean): void => {
    const newCarouselIndices: CarouselIndices = {
      leftIndexInView: carouselItems.length - itemsToShow,
      rightIndexInView: carouselItems.length,
      fromIndex: 0,
    };
    setCarouselIndices(newCarouselIndices);

    trackAfterSetState(fromClick);
  };

  const shiftLeft = (fromClick: boolean): void => {
    if (carouselRef.current) {
      const width = memoizedCarouselItemWidth();
      const scrollLeftMaxFallback = width * carouselItems.length + 10;

      const carouselScrollLeftMax =
        // NOTE: carouselRef.current.scrollLeftMax is only available on Firefox
        carouselRef.current.scrollWidth - carouselRef.current.clientWidth ||
        scrollLeftMaxFallback;

      if (carouselRef.current.scrollLeft > width - 25) {
        const leftItemBuffer = itemBuffer("left");
        shiftViewLeft(fromClick);
        scrollCarousel(leftItemBuffer - width * itemsToShow, 16);
      } else {
        shiftToEnd(fromClick);
        scrollCarousel(carouselScrollLeftMax, 10);
      }
    }
  };

  const restartAutoRotate = () => {
    if (shouldAutoRotate) {
      stopAutoRotate();
      startAutoRotate();
    }
  };

  const handleLeftClick = () => {
    shiftLeft(true);
    restartAutoRotate();
  };

  const handleRightClick = () => {
    shiftRight(true);
    restartAutoRotate();
  };

  const renderCarouselItems = (): Array<
    React.ReactElement<React.ComponentProps<any>, any>
  > => {
    return carouselItems.map((Item) => (
      <CarouselItem
        className={carouselItemClassName}
        item={Item}
        key={Item.key}
      />
    ));
  };

  return (
    <div
      className={classnames(
        "CarouselWrapper",
        styles.carouselWrapper,
        containerClassName,
        {
          [styles.listingPageCarousel]: listingPageCarousel,
        },
      )}
    >
      {shouldShowArrows ? (
        <button
          className={classnames("-arrow -left-arrow", styles.leftArrow)}
          onClick={handleLeftClick}
          title="Left"
          type="button"
        >
          <Caret direction="left" className={styles.arrowSvg} />
        </button>
      ) : null}
      <div
        className={classnames(
          "-carousel-items-wrapper",
          styles.items,
          wrapperClassName,
        )}
        ref={carouselRef}
      >
        {renderCarouselItems()}
      </div>
      {shouldShowArrows ? (
        <button
          className={classnames("-arrow -right-arrow", styles.rightArrow)}
          onClick={handleRightClick}
          title="Right"
          type="button"
        >
          <Caret direction="right" className={styles.arrowSvg} />
        </button>
      ) : null}
    </div>
  );
};

export default Carousel;
